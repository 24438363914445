import React from 'react';
import { extractContext, LocationInput as SemAppsLocationInput } from '@semapps/geo-components';

const LocationInput = props => (
  <SemAppsLocationInput
    mapboxConfig={{
      access_token: process.env.REACT_APP_MAPBOX_ACCESS_TOKEN,
      types: ['place', 'address'],
      country: []
    }}
    parse={value => ({
      type: 'schema:Place',
      'pair:label': value.place_name,
      'schema:longitude': value.center[0],
      'schema:latitude': value.center[1],
      'pair:hasPostalAddress': {
        type: 'schema:PostalAddress',
        'schema:addressLocality': value.place_type[0] === 'place' ? value.text : extractContext(value.context, 'place'),
        'schema:streetAddress': value.place_type[0] === 'address' ? [value.address, value.text].join(' ') : undefined,
        'schema:postalCode': extractContext(value.context, 'postcode'),
        'schema:addressCountry': extractContext(value.context, 'country')
      }
    })}
    optionText={resource => resource['pair:label']}
    {...props}
  />
);

export default LocationInput;
